<template>
  <Head>
    <title>Политика конфиденциальности {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Ознакомиться с политикой конфиденциальности жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
    <meta
      property="og:title"
      :content="'Политика конфиденциальности ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="Ознакомиться с политикой конфиденциальности жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
  </Head>
  <main class="main">
    <div class="container">
      <Breadcrumbs
        :links="[
          { name: 'Home', text: 'Главная' },
          { text: 'Политика конфиденциальности' },
        ]"
      />
      <h1 class="page-title h2">Политика конфиденци&shy;альности</h1>
      <p><b>1.</b> ОБЩИЕ ПОЛОЖЕНИЯ</p>
      <p>
        <b>1.1</b> АО «ГК ОСНОВА» (далее — Общество) предлагает Пользователям
        присоединиться к настоящему Пользовательскому соглашению на условиях,
        указанных в данном документе.
      </p>
      <p>
        <b>1.2</b> Настоящее Пользовательское соглашение определяет условия
        использования Сайта.
      </p>
      <p>
        <b>1.3</b> Фактом присоединения к Пользовательскому соглашению является
        любое использование Сайта, равно как и нажатие одной из кнопок (ссылок),
        размещенных на Сайте: «Отправить заявку», «Получить консультацию»,
        «Заказать звонок», «Отправить», «Забронировать», «Подписаться»,
        «Перезвонить мне», «Зарегистрироваться» или «Войти».
      </p>
      <p><b>2.</b> ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</p>
      <p>
        Персональные данные - любая информация, относящаяся к прямо или косвенно
        определенному или определяемому физическому лицу (субъекту персональных
        данных).<br />
        Общество - АО «ГК ОСНОВА», зарегистрированный по адресу: 142702,
        Московская обл., город Видное, поселок городского типа Бутово,
        территория жилой комплекс Бутово-Парк, д. 18, пом. 59.<br />
        Пользователь – физическое лицо, намеревающееся принять Пользовательское
        соглашение или принявшее его, а также использующее Сайт посредством
        информационно-телекоммуникационной сети «Интернет».<br />
        Сайт – это совокупность текстов, графических элементов, дизайна,
        изображений, программного кода, и иных результатов интеллектуальной
        деятельности, содержащихся в информационно -телекоммуникационной сети
        «Интернет» под доменным именем «very-botsad.ru», а также его поддоменов.
      </p>
      <p><b>3.</b> УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА</p>
      <p>
        <b>3.1</b> Использование Сайта означает, что Пользователь ознакомился и
        принимает Пользовательское соглашение, изложенное на данной странице.
        Если Пользователь не ознакомились и (или) не принимаете данные условия,
        то Пользователь обязан прекратить использование Сайта.
      </p>
      <p>
        <b>3.2</b> Общество оставляет за собой право в любое время изменять
        условия Пользовательского соглашения и Политику обработки персональных
        данных и рекомендует Пользователю регулярно просматривать их, чтобы
        знать обо всех изменениях. Продолжение использования Сайта после
        внесения Обществом изменений будет означать согласие Пользователя с
        измененными условиями.
      </p>
      <p>
        <b>3.3</b> Сайт носит исключительно информационный характер и никакая
        информация, опубликованная на нём, ни при каких условиях не является
        публичной офертой.
      </p>
      <p>
        <b>3.4</b> Пользователь вправе в свободной форме и по своему усмотрению
        знакомиться с содержимым Сайта.
      </p>
      <p>
        <b>3.5</b> Пользователь подтверждает, что он является дееспособным и
        достиг возраста, необходимого в соответствии с законодательством
        Российской Федерации для совершения сделок, предусмотренных
        Пользовательским соглашением.
      </p>
      <p>
        <b>3.6</b> Для правомерного использования (копирование, тиражирование)
        контента необходимо согласие владельца Сайта (Общества) или
        правообладателя материалов.
      </p>
      <p>
        <b>3.7</b> Использование общедоступных сведений или иной информации,
        доступ к которой не ограничен, разрешено при соблюдении установленных
        федеральными законами ограничений в отношении распространения такой
        информации.
      </p>
      <p>
        <b>3.8</b> Запрещены любые несанкционированные и (или) неправомерные
        действия в отношении Сайта, в том числе любые действия, направленные на
        нарушение работы Сайта или его компонентов.
      </p>
      <p><b>4.</b> ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
      <p>
        <b>4.1</b> Пользователь, используя Сайт и (или) предоставляя в любой
        форме (заполнение обратной формы, отправка письма на контактный e-mail
        адрес, использование контактного телефонного номера, передача
        электронных данных, cookies) свои персональные данные, выражает согласие
        на обработку персональных данных в соответствии с Федеральным законом «О
        персональных данных» от 27.07.2006 № 152-ФЗ. Обработка персональных
        данных Пользователя регулируется действующим законодательством
        Российской Федерации и Политикой обработки персональных данных,
        размещенной на Сайте.
      </p>
      <p>
        <b>4.2</b> Пользователь гарантирует достоверность персональной
        информации, предоставленной при принятии Пользовательского соглашения,
        принимает на себя всю ответственность за ее точность, полноту, а также
        поддерживает эту информацию в актуальном состоянии. Пользователь
        принимает на себя все возможные риски, связанные с его действиями,
        совершенными с ошибками или неточностями в предоставленной персональной
        информации.
      </p>
      <p><b>4.3</b> Перечень персональных данных, подлежащих обработке:</p>
      <p>
        • Персональная информация (фамилия, имя, отчество).<br />
        • Контактные данные (номер телефона, адрес электронной почты, псевдоним
        (идентификатор) в социальных сетях и сервисах коммуникаций).<br />
        • Электронные пользовательские данные (идентификаторы пользователя,
        сетевые адреса (IP), файлы cookies, идентификаторы устройств, размеры и
        разрешение экрана, сведения об аппаратном и программном обеспечении
        Пользователя (например, используемый браузер, операционная система,
        геолокация, языковые настройки, часовой пояс, время, статистика
        использования Сайта, действия Пользователя на Сайте, источники переходов
        на Сайт, отправленные поисковые и иные запросы).
      </p>
      <p>
        <b>4.4</b> Персональные данные Пользователей обрабатываются для
        следующих целей:
      </p>
      <p>
        • идентификации Пользователя, создания учетной записи;<br />
        • установления с Пользователем обратной связи, включая направление
        уведомлений, запросов, касающихся использования Сайта, оказания услуг,
        обработку запросов и заявок от Пользователя и направление по ним
        ответов;<br />
        • исполнение договорных обязательств Общества перед Пользователем;<br />
        • оказание информационных, справочных и консультационных услуг в рамках
        телефонного обслуживания;<br />
        • направление по электронной почте или телефону сообщений рекламного и
        информационного характера, содержащих информацию об услугах, товарах и
        работах Общества;<br />
        • повышение эффективности и удобства работы с Сайтом;<br />
        • проведение статистических и иных исследований на основе обезличенных
        данных.
      </p>
      <p>
        <b>4.5</b> В ходе обработки персональных данных будут совершены
        следующие действия: сбор, запись, систематизация, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передача
        (предоставление, доступ), блокирование, удаление, уничтожение.
      </p>
      <p>
        <b>4.6</b> Пользователь имеет право отозвать свое согласие на обработку
        персональных данных путем отправки письменного запроса в адрес Общества,
        в том числе посредством электронной почты info@gk-osnova.ru.
      </p>
      <p>
        <b>4.7</b> Если Пользователь считает, что Общество осуществляет
        обработку его персональных данных с нарушением требований Федерального
        закона от 27.07.2006 №152-ФЗ «О персональных данных» или иным образом
        нарушает его права и свободы, Пользователь вправе обжаловать действия
        или бездействие Общества в Уполномоченный орган по защите прав субъектов
        персональных данных или в судебном порядке.
      </p>
      <p>
        <b>5.</b> ОБРАБОТКА ЭЛЕКТРОННЫХ ПОЛЬЗОВАТЕЛЬСКИХ ДАННЫХ, ВКЛЮЧАЯ COOKIES
      </p>
      <p>
        <b>5.1</b> Файлы cookies — это небольшой фрагмент данных, который Сайт
        запрашивает у браузера, используемого на устройстве Пользователя. Файлы
        cookies содержат информацию о действиях Пользователя на Сайте, а также
        могут содержать сведения об оборудовании Пользователя, дату и время
        сессии.
      </p>
      <p>
        <b>5.2</b> Пользователю Сайта могут показываться всплывающие уведомления
        о сборе и обработке данных cookies со ссылкой на данное Пользовательское
        соглашение и Политику обработки персональных данных.
      </p>
      <p>
        <b>5.3</b> Если Пользователь не согласен с обработкой данных cookies, он
        может самостоятельно произвести настройку браузера запрещающую принимать
        и отправлять данные cookies для Сайта и (или) иным способом блокировать
        обработку данных cookies, или незамедлительно покинуть Сайт.
        Пользователь может самостоятельно управлять (просматривать, удалять) уже
        сохраненными данными cookies в браузере. При этом при блокировании или
        удалении файлов cookies возможна некорректная работа нашего сайта в
        Вашем браузере.
      </p>
      <p>
        <b>5.4</b> Файлы cookies используются Обществом в целях улучшения и
        обеспечения работоспособности Сайта.
      </p>
      <p><b>5.5</b> Виды используемых файлов cookies:</p>
      <p>
        • Сессионные. Существуют только во временной памяти в течение времени,
        когда Пользователь находится на странице Сайта. Браузеры обычно удаляют
        сессионные файлы cookies после того, как Пользователь закрывает окно
        Сайта. Сессионные файлы cookies позволяют Сайту помнить информацию о
        выборе Пользователя на предыдущей странице Сайта, чтобы избежать
        необходимости повторного ввода информации.<br />
        • Постоянные. Файлы cookies, которые хранятся на устройстве Пользователя
        и не удаляются при закрытии браузера. Такие файлы cookies позволяют
        идентифицировать Пользователя как уникального пользователя Сайта, и при
        возвращении на Сайт помогают вспомнить информацию о Пользователе и ранее
        совершенных им действиях.<br />
        • Обязательные. Минимальный набор файлов cookies, использование которых
        необходимо для корректной работы Cайта.<br />
        • Аналитические / маркетинговые. Включают в себя информацию о том, как
        Пользователь используете Сайт или насколько эффективны маркетинговые
        кампании Общества. Главная цель таких файлов cookies — улучшение функций
        и работы Сайта. Позволяют показывать Пользователям Сайта релевантные
        предложения для улучшения пользовательского опыта. На Сайте происходит
        сбор и обработка обезличенных данных с помощью сервиса
        интернет-статистики: «Яндекс.Метрика». Эта услуга предоставляется
        компанией ООО «ЯНДЕКС» (ИНН 7736207543). Дополнительную информацию о
        сервисе «Яндекс.Метрика» и политике конфиденциальности ООО «ЯНДЕКС»
        можно найти по адресу: https://yandex.ru/legal/confidential/ и
        https://yandex.ru/legal/metrica_termsofuse/.
      </p>
      <p>
        <b>5.6</b> В браузере на устройстве Пользователя может сохраняться
        информация (cookies), относящаяся к сайтам сторонних организаций,
        например, при использовании сторонних модулей, компонентов или иного
        программного обеспечения. Обработка такой информации регулируется
        политиками соответствующих сайтов, к которым она относится. Политика
        сторонних организаций может изменяться без уведомления Пользователя
        Сайта.
      </p>
      <p>
        <b>5.7</b> Примеры сторонних модулей, компонентов или программного
        обеспечения:
      </p>
      <p>
        • сервисы защиты от атак, способных заблокировать и (или) нарушить
        работу Сайта (DoS, DDoS, сканирование и поиск уязвимостей, попытки
        эксплуатации уязвимостей);<br />
        • сервисы и виджеты защиты от спама, интернет-ботов;<br />
        • счетчики посещений, аналитических и статистических сервисов, таких как
        «Яндекс.Метрика» для сбора статистики посещаемости общедоступных страниц
        Сайта;<br />
        • виджеты вспомогательных сервисов для предоставления обратной связи,
        организации чатов и иных видов коммуникаций с Пользователем;<br />
        • сервисы и модули оптимизации производительности работы Сайта;<br />
        • Сайт может использовать почтовый сервис Mail.ru. Эта услуга
        предоставляется компанией ООО «ВК» (ИНН 7743001840). Почта Mail.ru может
        использоваться для отправки и получения электронных сообщений через
        форму обратной связи на Сайте. Дополнительную информацию о почтовом
        сервисе Mail.ru и политике конфиденциальности ООО «ВК» можно найти по
        адресу: https://help.mail.ru/legal/terms/common/privacy.
      </p>
      <p><b>6.</b> ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
      <p>
        <b>6.1</b> В данное Пользовательское соглашения могут вноситься
        изменения и дополнения. Новая редакция Пользовательского соглашения
        вступает в силу с момента ее размещения на Сайте Общества. Пользователь
        обязан своевременно знакомится с действующим Пользовательским
        соглашением.
      </p>
      <p>
        <b>6.2</b> Действующая версия Пользовательского соглашения размещена в
        открытом доступе на Сайте: https://very-botsad.ru/.
      </p>
    </div>
  </main>
</template>
<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
  },
  data() {
    return {};
  },
};
</script>
